<template>
    <div class="card" style="min-height: calc(100vh - 180px)">
        <div class="bg-blue-light p-1">
            <TitleButton
                btnTitle="Add New"
                :showBtn="false"
                :showAddNew="false"
                title="Pending Goods Delivery"
                @onClickAddNewButton="navigateToAddPage"
            />
        </div>

        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickGo"
            >

                <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Customer Name: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>

                    <AsyncSelect placeholder="Select Customer Name" v-model="contactProfileId"
                        :api-service="fetchContactProfiles" :additional-query="additionalContactOption"
                        :additional-option="additionalOption" :option-selected="onChangeContact"
                        :key="contactRenderKey" />
                </div>
                <div class="col-12 col-sm-4 col-md-3 col-lg-2">
                    <label for="colFormLabel" class="col-form-label">PI NO: </label>
                    <input class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1" type="text" placeholder="Input PI NO"
                        v-model="billNumber">
                </div>
            </DateQuerySetter>
        </div>
        <div class="col-12 px-2 mt-2">
            <ListTable :purchase="purchaseList" route_name="create-challan"/>
        </div>
        <ContactDropdownFilter v-if="store.state.isModalOpenThree" @onSearchContact="onSearchContact"
            :companyRoles="companyRoles" :isFilterable="true" />
        <div class="px-2"><Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/></div>
    </div>
</template>

<script setup>
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import { ref, inject, onMounted, computed  } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import TitleButton from '@/components/atom/TitleButton'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/inventory/delivery-chalan/ListTable.vue'
import handleContact from "@/services/modules/contact";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'

const router = useRouter()
const store = useStore()
let offset = ref(20)
let purchaseList = ref([])
let contactProfiles = ref([])
let contactProfileId = ref(null)
const route = useRoute()
const showError =  inject('showError');
const showSuccess =  inject('showSuccess');
const billNumber = ref('')

const {fetchSalesOrderList} = handlePurchase()
const {fetchContactProfiles} = handleContact();

const companyId = computed(() => {
    return route.params.companyId
})
const startDate = computed(() => {
    return route.query.start
})
const endDate = computed(() => {
    return route.query.end
})
const page = computed(() => {
    return route.query.page
})

const onOpenContactSearchModal = () => {
    store.state.isModalOpenThree = true;
}

function navigateToAddPage() {
    router.push({name: 'sales-order-add', params: route.params, query:route.query})
}

function getQuery () {
    let query = '?company_id=' + companyId.value
    if(contactProfileId.value) query += '&contact_profile_id='+contactProfileId.value
    if(!startDate.value && !endDate.value) return query
    if(startDate.value) query += '&start_date='+startDate.value
    if(endDate.value) query += '&end_date='+endDate.value
    if(billNumber.value) query += '&bill_number='+billNumber.value
    if(page.value) query += '&page='+page.value
    query += '&offset='+offset.value
    return query
}

function onClickGo() {
    fetchSalesOrderList(getQuery())
    .then(res => {
        if(res.status) {
            purchaseList.value = res.date.data
            setPagination(res.date)
        }else {
            purchaseList.value = []
            store.commit("resetPagination")
        }
        
    })
}

function setPagination(data) {
    if(!data.data.length) return
    store.commit('setPaginationData', {
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

function onPageChange(page) {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    router.push({path: route.path, query: routeQuery})
    setTimeout(() => {
        onClickGo()
    },100)
}

onMounted(() => {
    let query = '?company_id=' + companyId.value
    fetchContactProfiles(query)
        .then(res => {
            contactProfiles.value = res.data
        })
        .catch(err => {
            showError("Something went wrong please try again")
        })
    onClickGo()
})

</script>
